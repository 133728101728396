/* eslint-disable */ 
import { useState } from "react";
// import PropTypes from "prop-types";
import * as React from "react";
import Button from "@mui/material/Button";

// import Stack from "@mui/material/Stack";
// import Avatar from "@mui/material/Avatar";
// import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
// import Typography from "@mui/material/Typography";
// import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Label from "../../components/label";
// import Iconify from "../..//components/iconify";

import moment from "moment";
import axios from "../../Config/Interceptor" 
import { Base_URL} from "../../Config/Config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import CircularProgress from "@mui/material/CircularProgress";


// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import NativeSelect from '@mui/material/NativeSelect';
import { Menu } from "@mui/material";

// ----------------------------------------------------------------------

export default function UserTableRow({
  selected,
  _id,
  mobile_no,
  name,
  order_id,
  // address,
  order_status,
  payment_status,
  subtotal,
  total_amount,
  created_on,
  handleClick,
  open_model,
  open_model_fee,
  getData,
  row_data,
  is_fee_details,
}) {

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [open_dilo, setOpenDilo] = useState(false);
  const [open_dilo_del, setOpenDiloDel] = useState(false);
  const [temp_id, setTempID] = useState("");
  // const [load, setLoad] = useState(false);

  const handleClickOpenDilo = (_id) => {
    setOpenDilo(true);
    setTempID(_id);
  };

  const handleClickOpenDiloDelete = () => {
    setOpenDiloDel(true);
    setTempID(_id);
  };

  const handleCloseDilo = (e, r) => {
    setOpenDilo(false);
    if (r === 1) {
      handleMoveToVerify(temp_id);
    }
  };

  const handleCloseDiloDelete = (e, r) => {
    setOpenDiloDel(false);
    if (r === 1) {
      handleDelete(temp_id);
    }
  };

  const Dilo_model = (_id) => {
    return (
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpenDilo}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open_dilo}
          onClose={handleCloseDilo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Before Move"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do You Really Want to Move this Student to Verified ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => handleCloseDilo(e, 0)}>Disagree</Button>
            <Button onClick={(e) => handleCloseDilo(e, 1)} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  const Dilo_model_delete = (_id) => {
    return (
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpenDilo}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open_dilo_del}
          onClose={handleCloseDiloDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Before Move"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do You Really Want to Delete this Student ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => handleCloseDiloDelete(e, 0)}>
              Disagree
            </Button>
            <Button onClick={(e) => handleCloseDiloDelete(e, 1)} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  // const onEdit = (id) => {
  //   console.log(id);
  // };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (id) => {
    console.log(id);
    navigate(`/edit-product/${id}`, {
      state: { ...row_data, is_from_simple_user: true },
    });
    setOpen(null);
  };

  const handleMoveToVerify = (id) => {
    console.log(id);
    axios
      .post(Base_URL + "/move-to-verfiy", { _id: id, status: "Approved" })
      .then((res) => {
        toast.success(res.data.message);
        getData();
        setTempID("");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setTempID("");
      });
  };

  const handleDelete = (id) => {
    console.log(id);

    axios
      .post(Base_URL + "del-students", { _id: id })
      .then((res) => {
        toast.success(res.data.message);
        setTempID("");
        getData();
      })
      .catch((err) => {
        setTempID("");
        toast.error(err.response.data.message);
      });
  };

  const changeOrderStatus = (ord_id, status) =>{
    // console.log(o_id, status)
    
  console.log("In change order status oid",ord_id);
  console.log("In change order status status",status);
    axios.post(`${Base_URL}admin/update-order-status?orderId=${ord_id}`, {
      "orderStatus": status
  })
    .then((res)=>{
      getData();
      toast.success(res.data.message);
    })
    .catch((err) => { setTempID("");
      toast.error(err.response.data.message);
    });
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
};

   
const handleStatusClose = () => {
  setAnchorEl(null);
};

const handleMenuItemClick = (ord_id, status) => {
  changeOrderStatus(ord_id, status);
  handleStatusClose();
};

  return (
    <>
      <Dilo_model />
      <Dilo_model_delete />
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{mobile_no}</TableCell>
        <TableCell>
            <Button
                variant="contained"
                color="secondary"
                aria-controls="order-status-menu"
                aria-haspopup="true"
                onClick={handleStatusClick}
            >
                {order_status} 
            </Button>
            <Menu
            id="order-status-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleStatusClose}
            >
                <MenuItem onClick={() => handleMenuItemClick(order_id,"Pending")}>Pending</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(order_id,"Processing")}>Processing</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(order_id,"Out For Delivery")} >Out For Delivery</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(order_id,"Delivered")}>Delivered</MenuItem>
            </Menu>
        </TableCell>

        <TableCell>
          <Label color={payment_status ? "success" : "warn"}>
            {payment_status}
          </Label>  
        
        </TableCell>

  

        <TableCell>{subtotal}</TableCell>

        <TableCell>{total_amount}</TableCell>

        <TableCell align="left">
          {moment(created_on).format("MMM Do YY")}
        </TableCell>

        <TableCell style={{ cursor: "pointer" }}>
          <Label
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={open_model}
          >
            {" "}
            View
          </Label>
        </TableCell>

      
          </TableRow> 

    
    </>
  );
}

// UserTableRow.propTypes = {
//   avatarUrl: PropTypes.any,
//   company: PropTypes.any,
//   handleClick: PropTypes.func,
//   isVerified: PropTypes.any,
//   name: PropTypes.any,
//   role: PropTypes.any,
//   selected: PropTypes.any,
//   status: PropTypes.string,
// };
