import React, { useEffect, useRef, useState } from "react";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

import * as loading_ani from '../../../Assets/Loading1.json'
import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// import { users } from "../../../_mock/user";
import moment from "moment";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";

import TableNoData from "../table-no-data";
import UserTableRow from "../user-table-row";
import UserTableHead from "../user-table-head";
import TableEmptyRows from "../table-empty-rows";
import UserTableToolbar from "../user-table-toolbar";
import { emptyRows, applyFilter, getComparator } from "../utils";
import Lottie from 'react-lottie';


import axios from "../../../Config/Interceptor" 
// import { useSelector } from "react-redux";
import { Base_URL } from "../../../Config/Config";
// import ExportToExcel from "../../../Excel-Exported/Exporter";

import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AddFeeDetails from "./Add-Fee-Details";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh", // Maximum height to trigger scrolling if content overflows
  overflowY: "auto"  // Enable vertical scrolling
};


// ----------------------------------------------------------------------

export default function UserPage() {
  
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [file, setFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [message, setMessage] = useState('');
  const fileInputRef = useRef(null);
  const location = useLocation();

  const handleFileChange = (event) => {
      // setFile(event.target.files[0]);
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      setPreviewURL(URL.createObjectURL(selectedFile));
  };

  const [load , setLoad ] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
        setMessage('Please select a file.');
        return;
    }

    
    const formData = new FormData();
    formData.append('vid', file);
  
    setLoad(true);
    axios.post(`${Base_URL}admin/upload-product-video/${location.state?.productId}`, formData)
    .then(() => {
            getData();
            toast.success("Video added successfully");
            // setLoad(false);
            handleCloseAddVideo();
            
        })
        .catch((error) => {
          // setLoad(false);
          toast.error("Error uploading video.")
            setMessage('Error uploading video.');
            console.error(error);
        });
};

const handleImageClick = () => {
    // Trigger the click event of the hidden file input
    fileInputRef.current.click();
};

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    if (id !== "") {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = stData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const [stData, setStData] = useState([]);

  // const [load, setLoad] = useState(false);

  const getData = () => {
    // setLoad(true)
    axios
      .get(`${Base_URL}admin/get-products`)
      .then((res) => {
        // console.log(res.data.data);
// setLoad(false)
        setStData(res.data.data.sort((a,b)=>b.product_id - a.product_id));
      })
      .catch(() => {
        // setLoad(false)
        setStData([]);
      });
  };

  const deleteVideo = (p_id) => {
    axios
      .post(`${Base_URL}admin/delete-video/${p_id}`)
      .then(() => {
        getData();
        handleClose();
        setMessage('');
        toast("Delete Video Successfully")
    })
    .catch((error) => {
        setMessage('Error Delete video.');
        console.error(error);
    });
  };

  

  useEffect(() => {
    getData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: stData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const [mod_data, setModData] = React.useState({});

  const [open, setOpen] = React.useState(false);
  const handleOpen = (dt, j) => {
    setModData(j);
    setOpen(true);
  };
  const handleClose = () => {
    setModData({});
    setOpen(false);
  };

  const addProduct = () => {
    navigate("/add-product");
  };

  const notFound = !dataFiltered.length && !!filterName;

  // const [feeData, setFeeData] = useState({});

  // const getFeeDetails = (st_id) => {
  //   axios
  //     .get(Base_URL + "get-st-fee-dt", { params: { _id: st_id } })
  //     .then((res) => {
  //       setFeeData(res.data.data);

  //       console.log(res.data.data);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };

  const defaultOptions_loading = {
    loop: true,
    autoplay: true, 
    animationData: loading_ani,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const ModelBox = () => {
    return (
      <div>
      
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
         
            <TableContainer component={Paper}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={5}
              >
                <Typography variant="h4">DealProducts Basic Details</Typography>
              </Stack>
              <hr></hr>
              <Table
                sx={{ minWidth: 500 }}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Product Name</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.name}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Product Id</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.product_id}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Cat Name </TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.cat_name}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Description</TableCell>

                   
                                           
                                           
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left"  dangerouslySetInnerHTML={{ __html: mod_data.description }}></TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Created Date</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">
                      {moment(mod_data.created_on).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Discount</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.discount}%</TableCell>
                  </TableRow>

                  {mod_data.fabric?
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">Fabric</TableCell>
                      <TableCell align="left">:</TableCell>
                      <TableCell align="left">{mod_data.fabric }</TableCell>
                    </TableRow>
                    :<></>
                  }

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Feature/ Specification</TableCell>
                    <TableCell align="left">:</TableCell>
                  
                    <TableCell align="left" dangerouslySetInnerHTML={{ __html: mod_data.feature}}></TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Added In Featured</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.featured ? "Yes" : "No"}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Stock</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.qty_status ? "In Stock" : "Out Of Stock"}</TableCell>
                  </TableRow>

                   <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Product Show On UI </TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.is_active  ? " Visible" : "Not Visible"}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Original Price</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.o_price}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Start Price</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.s_price}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Product Id</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.product_id}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Rating</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.rating  ? `${mod_data.rating}/5` : 'No Rating'}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Size</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">
                      {mod_data.size
                        ? (mod_data.size).map(String).join(", ")
                        : "Not Available" }
                    </TableCell>
                  </TableRow>
                  <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">Color</TableCell>
                  <TableCell align="left">:</TableCell>
                  <TableCell align="left">
                    {mod_data.color
                      ?  (mod_data.color).map(String).join(", ")
                      : "Not Available" }
                  </TableCell>
                </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Sku Id</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.sku_id}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Set Type</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.settype}</TableCell>
                  </TableRow>

                   <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">Brand</TableCell>
                    <TableCell align="left">:</TableCell>
                    <TableCell align="left">{mod_data.brand}</TableCell>
                  </TableRow>
                 
                </TableBody>
              </Table>
              <hr></hr>
              <div>
                  <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                <Typography variant="h4">Product Images</Typography>
              </Stack>
                  {
                    mod_data.images && Array.isArray(mod_data.images) && mod_data.images.length > 0  
                    ?
                    mod_data.images.map((img,index)=>(
                      <img src={img}  key={index} width="100px" height="100px" style={{marginLeft:"10px"}}/>
                    ))
                    :<></>
                  }
              </div>

              {mod_data.video?
                <>
                <hr/>
                <div>
                <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                  mb={1}
                  >
                  <Typography variant="h4">Product Video</Typography>
                  </Stack>
                   
                  <video src= { mod_data.video} width="364px" height="364px" controls /> 
                  <div>
                    <button style={{border:"1px solid blue" , backgroundColor:"blue" , color:"white", padding:"8px", width:"364px", fontSize:"15px", cursor  :"pointer"}} onClick={()=>deleteVideo(mod_data.product_id)}>Delete Video </button>
                  </div>
                  
                  </div>
                  </>
                :<></>
              }
            </TableContainer>
            
          </Box>
        </Modal>
            
      </div>
    );

    
  };

  const [open_fee, setOpenFee] = React.useState(false);
  const handleOpenFee = (dt, j) => {
    setModData(j);
    setOpenFee(true);
  };
  const handleCloseFee = () => {
    setModData({});
    setOpenFee(false);
  };

  const FeeModelBox = () => {
    return (
      <div>
        <Modal
          keepMounted
          open={open_fee}
          onClose={handleCloseFee}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <TableContainer component={Paper}>
              <AddFeeDetails
                product_id={mod_data.product_id}
                name={mod_data.name}
                setOpenFee_handler={setOpenFee}
                is_fee_details={mod_data.is_fee_details}
              />
            </TableContainer>
          </Box>
        </Modal>
      </div>
    );
  };

  const [open_add_video, setOpenAddVideo] = React.useState(false);
  const handleOpenAddVideo = ( j) => {
    setModData(j);
    setOpenAddVideo(true);
  };

  const handleCloseAddVideo = () => {
    setModData({});
    setOpenAddVideo(false);
    // setOpenAddVideo(false);
    setFile(null);
    setPreviewURL(null);
    setMessage('');
  };

  const AddVideoModelBox = () => {
    return (
      <div>
        <Modal
          keepMounted
          open={open_add_video}
          onClose={handleCloseAddVideo}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style}>
        {load == true ? 
          <>
         <Lottie options={defaultOptions_loading}
         height={200}
         width={200}/>
         <h1   style={{textAlign: "center"  , fontSize: 12 , color:"red"}}>Please wait while we are processing your request</h1>
         </> :
        <TableContainer component={Paper}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            
          >
            <Typography variant="h4">DealProducts Video Added</Typography>
          </Stack>
          <hr></hr>


          <div>  
          <form  onSubmit={handleSubmit} >
          <div style={{display:'flex'}}>
              <div>
                  <label htmlFor="videoFile">
                      <h6><b>Choose a video file:</b></h6>
                  </label>
                  {/* Box with default image */}
                  <div style={{ border: '1px solid grey', padding: '10px', marginBottom: '10px', cursor: 'pointer',display:'flex',justifyContent:'center',backgroundColor:'grey',width:'40vh' }} onClick={handleImageClick}>
                      <img src='/assets/images/avatars/avatar_1.jpg' alt="Default" style={{ width: '35vh', height: 'auto', marginBottom: '5px' }} />
                      <input
                          ref={fileInputRef}
                          type="file"
                          className="form-control"
                          id="videoFile"
                          accept="video/*"
                          onChange={handleFileChange}
                          style={{ display: 'none' }} // Hide the file input
                      />
                  </div>
              </div>
              {previewURL && (
                <div style={{marginLeft: '20px'}}>
                  <h3>Video Preview:</h3>
                  <video controls width="100%">
                    <source src={previewURL} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
              {message && <div>{message}</div>}
              </div>
              
              <div>
                  <button style={{border:"1px solid blue" , backgroundColor:"blue" , color:"white", padding:"8px", width:"100%", fontSize:"15px" , cursor  :"pointer"}} > Submit </button>
                  
              </div>
          </form>
          </div>
          
          
         
        </TableContainer>
        }
      </Box>
        </Modal>
      </div>
    );
  };

  return (
    <Container>
    <Helmet>
        <title> Products | Dealkarts </title>
      </Helmet>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Deal Products</Typography>

        <Button
          variant="contained"
          onClick={addProduct}
          color="inherit"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Add Product
        </Button>
      </Stack>

      <Card>
        <UserTableToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          stData={stData}
        />

        <Scrollbar>
          <TableContainer sx={{ overflow: "unset" }}>
         
            <Table sx={{ minWidth: 800 }}>
            <UserTableHead
              order={order}
              orderBy={orderBy}
              rowCount={stData.length}
              numSelected={selected.length}
              onRequestSort={handleSort}
              onSelectAllClick={handleSelectAllClick}
              headLabel={[
                { id: "sku_id", label: "SKU Id" },
                { id: "name", label: "Product Name" },
                { id: "cat_name", label: "Category" },
                { id: "s_price", label: "Price" },
                { id: "featured", label: "Featured" },
                { id: "qty_status", label: "IN Stock" },
                { id: "is_active", label: "Product Show" },
                { id: "video", label: "Video" },
                { id: "detail", label: "Details" },
                { id: "Created On ", label: "Create" },
                { id: "action", label: "Action" },
              ]}
            />
            <TableBody>
              {dataFiltered
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row,index) => {
                  return (
                    <UserTableRow
                      key={index}
                      sku_id={row.sku_id}
                      name={row.name.slice(0, 10)}
                      avatarUrl={row.images[0]}
                      o_price={row.o_price}
                      s_price={row.s_price}
                      featured={row.featured}
                      cat_name={row.cat_name}
                      qty_status={row.qty_status}
                      is_active={row.is_active}
                      created_on={row.created_on}
                      selected={selected.indexOf(row.name) !== -1}
                      handleClick={(event) => handleClick(event, row.name)}
                      open_model={(e) => handleOpen(e, row)}
                      open_video_add={(e)=> handleOpenAddVideo(e, row)}
                      video= {row.video}
                      open_model_fee={(e) => handleOpenFee(e, row)}
                      _id={row.product_id}
                      getData={getData}
                      row_data={row}
                      is_fee_details={row.is_fee_details}
                    />
                  );
                })}

              <TableEmptyRows
                height={77}
                emptyRows={emptyRows(page, rowsPerPage, stData.length)}
              />

              {notFound && <TableNoData query={filterName} />}
            </TableBody>
          </Table>
        
           
          </TableContainer>
        </Scrollbar>

        <TablePagination
        page={page}
        component="div"
        count={stData.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Card>
      <ModelBox />
      <FeeModelBox />
      <AddVideoModelBox />
    </Container>
  );
}
