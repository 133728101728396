/* eslint-disable */ 
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import App from './app';
import './global.css'

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <HelmetProvider>
  <BrowserRouter>
  <Suspense>
  <ToastContainer />
  <App />
  </Suspense>
  </BrowserRouter>
  </HelmetProvider>
  // </React.StrictMode>
);
