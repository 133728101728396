import { useState } from 'react';
import PropTypes from 'prop-types';
/* eslint-disable */ 
import * as React from 'react';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Label from '../../components/label';
import Iconify from '../..//components/iconify';

import moment  from 'moment';
import axios from "../../Config/Interceptor" 
import { Base_URL } from '../../Config/Config';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
// import { Switch } from '@mui/material';
// import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function UserTableRow({
  selected,
  _id,
  sku_id,
  name,
  avatarUrl,
  s_price,
  o_price,
  featured,
  cat_name,
  video,
  // email,
  qty_status,
  is_active,
  created_on,
  handleClick,
  open_model,
  open_video_add,
  // open_model_fee,
  getData,
  row_data,
  // is_fee_details
}) {

 
  const navigate = useNavigate()

  const handleVideoClick = () => {
    
    navigate('/deal-product' , {state: {productId : _id}})
  }
  
  const [open, setOpen] = React.useState(false);
  
  const [open_dilo, setOpenDilo] = useState(false);
  const [open_dilo_del, setOpenDiloDel] = useState(false);
  const [temp_id , setTempID] = useState("")
  // const [load , setLoad ] = useState(false)
  // const handleClickOpenDilo = (_id) => {
  //   setOpenDilo(true);
  //   setTempID(_id)
  // };
  // const handleClickOpenDiloDelete = () => {
  //   setOpenDiloDel(true);
  //   setTempID(_id)
  // };

  const handleCloseDilo = (e,r) => {
    setOpenDilo(false);
    if(r === 1)
    {

      handleMoveToVerify(temp_id)
    }
  };
  
  const handleCloseDiloDelete = (e,r) => {
    setOpenDiloDel(false);
    if(r === 1)
    {

      // handleDelete(temp_id)
    }
  };

  const Dilo_model  = ()=>{
    
    return (
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpenDilo}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open_dilo}
          onClose={handleCloseDilo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Before Move"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do You Really Want to Move this Student to Verified ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e)=>handleCloseDilo(e,0)}>Disagree</Button>
            <Button onClick={(e)=>handleCloseDilo(e,1)} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }


  const Dilo_model_delete  = ()=>{
    
    return (
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpenDilo}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open_dilo_del}
          onClose={handleCloseDiloDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Before Move"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do You Really Want to Delete this Product  ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e)=>handleCloseDiloDelete(e,0)}>Disagree</Button>
            <Button onClick={(e)=>handleCloseDiloDelete(e,1)} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  const onEdit =  (id) =>{
    navigate(`/edit-product/${id}` , {state : {...row_data , is_from_simple_user : true}})
  }

  const onEditImage =  (id) =>{
    console.log(id)
    navigate(`/edit-image/${id}` , {state : {...row_data , is_from_simple_user : true}})
  }

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (id) => {
    console.log(id)
    // navigate(`/edit-product/${id}` , {state : {...row_data , is_from_simple_user : true}})
    setOpen(null);
  };


  const handleMoveToVerify = (id)=>{


    axios.post(Base_URL + '/move-to-verfiy', {_id : id , status : "Approved"}).then((res)=>{
      toast.success(res.data.message)
      getData()
      setTempID("")

    }).catch((err)=>{
      toast.error(err.response.data.message)
      setTempID("")
    })


  }
  
  const handleDelete = (id)=>{
    console.log(id)

    axios.post(`${Base_URL}admin/delete-product/${id}`).then((res)=>{
      toast.success(res.data.message)
      getData();
      handleCloseMenu();
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })
  }

  // const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleUpdateQtyStatus = (id) =>{
    axios.post(`${Base_URL}admin/updateQtyStatus/${id}`)
    .then((result) => {
      getData();
      console.log(result.data);
      toast.success(result.data.message)

    })
    .catch((err)=>{
      console.log(err);
      toast.error(err.response.data.message)
    })
  }

  const handleUpdateFeaturedStatus = (id) =>{
    axios.post(`${Base_URL}admin/update-featured-status/${id}`)
    .then((result) => {
      getData();
      console.log(result.data);
      toast.success(result.data.message)

    })
    .catch((err)=>{
      console.log(err);
      toast.error(err.response.data.message)
    })
  }

  
  const handleUpdateProductShowUI = (id) =>{
    axios.post(`${Base_URL}admin/toggle-product-status/${id}`)
    .then((result) => {
      getData();
      console.log(result.data);
      toast.success(result.data.message)

    })
    .catch((err)=>{
      console.log(err);
      toast.error(err.response.data.message)
    })
  }

  return (
    <>
    <Dilo_model/>
    <Dilo_model_delete/>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>
        <TableCell>{sku_id}</TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="left" spacing={2}>
            <Avatar alt={name} src={avatarUrl} />
            <Typography variant="subtitle2" noWrap alt={name}>
              {name}
            </Typography>
          </Stack>
        </TableCell>

       

        <TableCell>{cat_name}</TableCell>
        <TableCell>₹{s_price}/-<br/><del>₹{o_price}/-</del></TableCell>
    
        <TableCell>
        {featured ? (
          <ThumbUpAltIcon
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => handleUpdateFeaturedStatus(_id)}
          />
        ) : (
          <ThumbDownAltIcon
            style={{ cursor: 'pointer' }}
            onClick={() => handleUpdateFeaturedStatus(_id)}
          />
        )}
      </TableCell>

      

       
        <TableCell>
        {qty_status ? (
          <ThumbUpAltIcon
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => handleUpdateQtyStatus(_id)}
          />
        ) : (
          <ThumbDownAltIcon
            style={{ cursor: 'pointer' }}
            onClick={() => handleUpdateQtyStatus(_id)}
          />
        )}
      </TableCell>

      
      <TableCell>
      {is_active ? (
        <ThumbUpAltIcon
          style={{ cursor: 'pointer', color: 'green' }}
          onClick={() => handleUpdateProductShowUI(_id)}
        />
      ) : (
        <ThumbDownAltIcon
          style={{ cursor: 'pointer', color: 'red' }}
          onClick={() => handleUpdateProductShowUI(_id)}
        />
      )}
    </TableCell>

        
        <TableCell style={{cursor  :"pointer"}}>
        {
          video ? 
            <Label color='primary' style={{cursor  :"pointer"}} onClick={open_model} > Show </Label>
            :
            <div onClick={open_video_add} >
            <Label color='secondary' style={{cursor  :"pointer"}} onClick={handleVideoClick} > Add </Label>
            </div>
        }
      </TableCell>

      <TableCell style={{cursor  :"pointer"}}>
      <Label color='primary' style={{cursor  :"pointer"}} onClick={open_model}  >  View</Label>
    </TableCell>

        <TableCell align="left">{moment(created_on).format("MMM Do YY")}</TableCell>

        <TableCell align="left">
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
          </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 150 },
        }}
      >
        <MenuItem onClick={()=> onEdit(_id)}>
          <Iconify   icon="eva:edit-fill" sx={{ mr: 2 }} />
           Edit Product
        </MenuItem>

        <MenuItem onClick={()=> onEditImage(_id)}>
          <Iconify   icon="eva:edit-fill" sx={{ mr: 2 }} />
          Edit Image 
        </MenuItem>

        {/*<MenuItem onClick={()=>handleDelete(_id)} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }}  />
          Delete
        </MenuItem> */}

      </Popover>
    </>
  );
}


UserTableRow.propTypes = {
  selected: PropTypes.any,
  _id: PropTypes.any,
  sku_id: PropTypes.any,
  handleClick: PropTypes.func,
  open_model: PropTypes.func,
  open_video_add: PropTypes.func,
  avatarUrl: PropTypes.any,
  s_price: PropTypes.any,
  o_price: PropTypes.any,
  name: PropTypes.any,
  cat_name: PropTypes.any,
  video: PropTypes.any,
  qty_status: PropTypes.any,
  created_on: PropTypes.any,
  getData: PropTypes.any,
  row_data: PropTypes.any,
};









