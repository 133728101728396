import axios from 'axios';
import { Base_URL } from './Config';
import store from "../Store/Store";


// Create an axios instance
var apiClient = axios.create();
// Add a request interceptor
apiClient.interceptors.request.use(
  async (config) => {
    // Get the token from local storage or state
    let token = ""
  let at  =  JSON.parse(localStorage.getItem('reduxStore'));
  let bt  =  at && at.LoginReducer && at.LoginReducer.data && at.LoginReducer.data.token ? at.LoginReducer.data.token : null;

  token  = bt;  
    if (token) {
      config.headers.authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

apiClient.interceptors.response.use(
  
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;


          
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `${token}`;
            // console.log("Test-2")

            return apiClient(originalRequest);
          })
          .catch((err) => {
            // console.log("Test-1")
            // window.location.replace('/')
            store.dispatch({type : "LOGOUT" , data: {}});

            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        let token = ""
          let at  =  JSON.parse(localStorage.getItem('reduxStore'));
          // let bt  =  at.LoginReducer.data.token
          let bt  =  at && at.LoginReducer && at.LoginReducer.data && at.LoginReducer.data.token ? at.LoginReducer.data.token : null;
          token  = bt;  
        // console.log("Lion" ,  token)
        axios
          .post(`${Base_URL}admin/generate-refresh-token`, {  }, {headers : {Authorization : token}})
          .then(( data ) => {
            // console.log(data)
            // localStorage.setItem('token', data.token);
            // apiClient.defaults.headers.common['Authorization'] = data.token;
            // originalRequest.headers.Authorization =  data.token;
            // console.log("Step-1")
            const newToken = data.headers.authorization;
            // console.log("New Token"  , newToken)
            // console.log("Step-2")
            // const userData = store.getState().LoginReducer.data;
            const userData = store.getState() && store.getState().LoginReducer && store.getState().LoginReducer.data ? store.getState().LoginReducer.data : null;
        
            
            store.dispatch({ type: "LOGIN", data: userData });
            localStorage.setItem('reduxStore', JSON.stringify(store.getState()));
            if(userData){
              userData.token = newToken;
              store.dispatch({type: "LOGIN", data: userData});
            }
            processQueue(null, data.token);
            resolve(apiClient(originalRequest));
          })
          .catch((err) => {
            store.dispatch({type : "LOGOUT" , data: {}});
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default apiClient;
