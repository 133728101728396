import { useState } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Iconify from '../../components/iconify';
import axios from "../../Config/Interceptor" 
import { Base_URL } from '../../Config/Config';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

export default function UserTableRow({
  selected,
  _id,
  banner,
  description,
  handleClick,
  // open_model,
  // open_model_fee,
  getData,
  // row_data,
  // is_fee_details
}) {

  // const navigate = useNavigate()

  const [open, setOpen] = React.useState(false);
  const [open_dilo, setOpenDilo] = useState(false);
  const [open_dilo_del, setOpenDiloDel] = useState(false);
  const [temp_id , setTempID] = useState("")

  const handleCloseDilo = (e,r) => {
    setOpenDilo(false);
    if(r === 1)
    {
      handleMoveToVerify(temp_id)
    }
  };
  
  const handleCloseDiloDelete = (e,r) => {
    setOpenDiloDel(false);
    if(r === 1)
    {
      handleDelete(temp_id)
    }
  };

  const Dilo_model  = (_id)=>{
    console.log(_id);
    return (
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpenDilo}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open_dilo}
          onClose={handleCloseDilo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Before Move"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do You Really Want to Move this Student to Verified ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e)=>handleCloseDilo(e,0)}>Disagree</Button>
            <Button onClick={(e)=>handleCloseDilo(e,1)} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }


  const Dilo_model_delete  = (_id)=>{
    console.log(_id);
    return (
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpenDilo}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open_dilo_del}
          onClose={handleCloseDiloDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Before Move"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do You Really Want to Delete this Product  ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e)=>handleCloseDiloDelete(e,0)}>Disagree</Button>
            <Button onClick={(e)=>handleCloseDiloDelete(e,1)} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  // const onEdit =  (id) =>{
  //   console.log(id)
  //   navigate(`/edit-product/${id}` , {state : {...row_data , is_from_simple_user : true}})
  // }

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (id) => {
    console.log(id)
    // navigate(`/edit-product/${id}` , {state : {...row_data , is_from_simple_user : true}})
    setOpen(null);
  };


  const handleMoveToVerify = (id)=>{
    console.log(id)
    axios.post(Base_URL + '/move-to-verfiy', {_id : id , status : "Approved"}).then((res)=>{
      toast.success(res.data.message)
      getData()
      setTempID("")

    }).catch((err)=>{
      toast.error(err.response.data.message)
      setTempID("")
    })
  }

  const handleDelete = (id)=>{
    console.log(id)
    axios.post(`${Base_URL}admin/delete-banner/${id}`).then((res)=>{
      toast.success(res.data.message)
      getData();
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })
  }

  // const label = { inputProps: { 'aria-label': 'Switch demo' } };
 

  return (
    <>
    <Dilo_model/>
    <Dilo_model_delete/>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>
        <TableCell>{_id}</TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="left" spacing={2}>
            <img src={banner} width="200px" height="80px" />
          </Stack>
        </TableCell>

        {/* <TableCell>{college.slice(0,15) + '...'}</TableCell> */}

        <TableCell>{description}</TableCell>
       
        <TableCell align="left">
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
          </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
       
        <MenuItem onClick={()=>handleDelete(_id)} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }}  />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}


UserTableRow.propTypes = {                                                                                                                                                                   
  banner: PropTypes.any,
  description: PropTypes.any,
  handleClick: PropTypes.func,
  getData: PropTypes.any,
  _id: PropTypes.any,
//   role: PropTypes.any,
  selected: PropTypes.any,
//   status: PropTypes.string,
};









