import { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";

// import { users } from "../../../_mock/user";
// import moment from "moment";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";

import TableNoData from "../table-no-data";
import UserTableRow from "../user-table-row";
import UserTableHead from "../user-table-head";
import TableEmptyRows from "../table-empty-rows";
import UserTableToolbar from "../user-table-toolbar";
import { emptyRows, applyFilter, getComparator } from "../utils";



import axios from "../../../Config/Interceptor" 
// import { useSelector } from "react-redux";
import { Base_URL } from "../../../Config/Config";
// import ExportToExcel from "../../../Excel-Exported/Exporter";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import AddFeeDetails from "./Add-Fee-Details";
// import { toast } from "react-toastify";


// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 800,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
//   maxHeight: "80vh", // Maximum height to trigger scrolling if content overflows
//   overflowY: "auto"  // Enable vertical scrolling
// };


// ----------------------------------------------------------------------

export default function UserPage() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    if (id !== "") {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = stData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const [stData, setStData] = useState([]);

  // const [load, setLoad] = useState(false);

  const getData = () => {
    axios
    .get(`${Base_URL}admin/get-promocodes`)
      .then((res) => {
        // console.log(res.data.data);

        setStData(res.data.data);
      })
      .catch(() => {
        setStData([]);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: stData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  // const [mod_data, setModData] = React.useState({});

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = (dt, j) => {
  //   // setModData(j);
  //   // setOpen(true);
  // };

  // const handleClose = () => {
  //   // setModData({});
  //   // setOpen(false);
  // };

  const addPromo = () => {
    navigate("/add-promo");
  };

  const notFound = !dataFiltered.length && !!filterName;

  // const [feeData, setFeeData] = useState({});

 
  // const [open_fee, setOpenFee] = React.useState(false);


 

  // const [open_add_video, setOpenAddVideo] = React.useState(false);
  // const handleOpenAddVideo = (dt, j) => {
  //   // setModData(j);
  //   setOpenAddVideo(true);
  // };

  // const handleCloseAddVideo = () => {
  //   // setModData({});
  //   setOpenAddVideo(false);
  // };

 
  return (
    <Container>
    <Helmet>
        <title> Promo | Dealkarts </title>
      </Helmet>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Deal PromoCode</Typography>

        <Button
          variant="contained"
          onClick={addPromo}
          color="inherit"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Add PromoCode
        </Button>
      </Stack>

      <Card>
        <UserTableToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          stData={stData}
        />

        <Scrollbar>
          <TableContainer sx={{ overflow: "unset" }}>
            <Table sx={{ minWidth: 800 }}>
              <UserTableHead
                order={order}
                orderBy={orderBy}
                rowCount={stData.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                onSelectAllClick={handleSelectAllClick}
                headLabel={[
                  { id: "code_id", label: "Code Id" },
                  { id: "code", label: "Code Name" },
                  { id: "discount", label: "Discount" },
                  { id: "Created On ", label: "Create" },
                  { id: "action", label: "Action" },
                ]}
              />
              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <UserTableRow
                      key={index}
                      code_id={row.code_id}
                        name={row.name}
                        code={row.code}
                        discount={row.discount}
                        qty_status={row.qty_status}
                        created_on={row.created_on}
                        selected={selected.indexOf(row.name) !== -1}
                        handleClick={(event) => handleClick(event, row.name)}
                        // open_model={(e) => handleOpen(e, row)}
                        _id={row.code_id}
                        getData={getData}
                        row_data={row}
                        is_fee_details={row.is_fee_details}
                      />
                    );
                  })}

                <TableEmptyRows
                  height={77}
                  emptyRows={emptyRows(page, rowsPerPage, stData.length)}
                />

                {notFound && <TableNoData query={filterName} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
        page={page}
        component="div"
        count={stData.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Card>
      
    </Container>
  );
}
