import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';
import HomeBannersView from '../sections/DealBanner/view/homeBanners';
import ManageOrdersView from '../sections/ManageOrders/view/manageOrders';
import DealPromoView from '../sections/DealPromo/view/dealPromo';
import DealSizeView from '../sections/DealSize/view/dealSize';
import DealProductsView from '../sections/DealProducts/view/dealProducts';

export const IndexPage = lazy(() => import('../pages/app'));
// export const BlogPage = lazy(() => import('../pages/blog'));
// export const GetBestOffers  =  lazy(()=> import('../pages/Get-Best-Offers'))
// export const VerifiedStu  =  lazy(()=> import('../pages/Verified-Students'))
// export const AddStudentPage  =  lazy(()=> import('../pages/Add-Student'))
export const AddProductPage  =  lazy(()=> import('../pages/Add-Product'))
export const AddBannerPage  =  lazy(()=> import('../pages/Add-Banner'))
export const AddPromoPage  =  lazy(()=> import('../pages/Add-Promo'))
export const AddSizePage  =  lazy(()=> import('../pages/Add-Size'))

// export const AddIdSequencePage  =  lazy(()=> import('../pages/Add-ID-Sequence'))
// export const EditStudentPage  =  lazy(()=> import('../pages/Edit-Student'))
export const EditProductPage  =  lazy(()=> import('../pages/Edit-Product'))
export const EditImagePage  =  lazy(()=> import('../pages/Edit-Image'))
// export const DownloadBros  =  lazy(()=> import('../pages/Brochures-data'))
export const UserPage = lazy(() => import('../pages/user'));

export const LoginPage = lazy(() => import('../pages/login'));
export const ProductsPage = lazy(() => import('../pages/products'));

export const Page404 = lazy(() => import('../pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'user', element: <UserPage /> },
        // { path: 'products', element: <ProductsPage /> },
        // { path: 'best-offers', element: <GetBestOffers /> },
        // { path: 'blog', element: <BlogPage /> },
        // { path: 'bros-data', element: <DownloadBros /> },
        // { path: 'veri-stu', element: <VerifiedStu /> },
        // { path: 'add-student', element: <AddStudentPage /> },
        { path: 'add-product', element: <AddProductPage /> },
        { path: 'add-banner', element: <AddBannerPage /> },
        { path: 'add-promo', element: <AddPromoPage /> },
        { path: 'add-size', element: <AddSizePage /> },
        // { path: 'add-id-seq', element: <AddIdSequencePage /> },
        // { path: 'edit-student/:id', element: <EditStudentPage /> },

        { path: 'edit-product/:id', element: <EditProductPage /> },
        { path: 'edit-image/:id', element: <EditImagePage /> },
        { path: 'deal-product', element: <DealProductsView/> },
        { path: 'deal-banners', element: <HomeBannersView/> },
        { path: 'deal-orders', element: <ManageOrdersView/> },
        { path: 'deal-promo', element: <DealPromoView/> },
        { path: 'deal-size', element: <DealSizeView/> },
      ],
    },
    // {
    //   path: 'login',  
    //   element: <LoginPage />,
    // },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
