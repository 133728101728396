// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';

// // ----------------------------------------------------------------------

// export function useResponsive(query, start, end) {
//   const theme = useTheme();

//   const mediaUp = useMediaQuery(theme.breakpoints.up(start));

//   const mediaDown = useMediaQuery(theme.breakpoints.down(start));

//   const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));

//   const mediaOnly = useMediaQuery(theme.breakpoints.only(start));

//   if (query === 'up') {
//     return mediaUp;
//   }

//   if (query === 'down') {
//     return mediaDown;
//   }

//   if (query === 'between') {
//     return mediaBetween;
//   }

//   return mediaOnly;
// }

// // ----------------------------------------------------------------------

// export function useWidth() {
//   const theme = useTheme();

//   const keys = [...theme.breakpoints.keys].reverse();

//   return (
//     keys.reduce((output, key) => {
//       const matches = useMediaQuery(theme.breakpoints.up(key));

//       return !output && matches ? key : output;
//     }, null) || 'xs'
//   );
// }



import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// ----------------------------------------------------------------------

export function useResponsive(query, start, end) {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(start));
  const mediaDown = useMediaQuery(theme.breakpoints.down(start));
  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));
  const mediaOnly = useMediaQuery(theme.breakpoints.only(start));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  return mediaOnly;
}

// ----------------------------------------------------------------------

export function useWidth() {
  const theme = useTheme();
  
  const isXs = useMediaQuery(theme.breakpoints.up('xs'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  if (isXl) return 'xl';
  if (isLg) return 'lg';
  if (isMd) return 'md';
  if (isSm) return 'sm';
  if (isXs) return 'xs';

  return 'xs';
}
