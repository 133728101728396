





const LoginReducer = (state , action) =>{

    if(typeof  state == 'undefined')
    {
        return{

            data : {}
        }
    }

    switch(action.type)
    {


        case "LOGIN":
            return{
                ...state,
                data :  action.data
            }
        case "LOGOUT":
            return{
                ...state,
                data :  {}
            }
            

        default:
            return{
                ...state
            }
    }



}

export default LoginReducer