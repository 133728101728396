import React, { useEffect, useState } from "react";

import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
// import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";

// import { users } from "../../../_mock/user";
import moment from "moment";
// import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";

import TableNoData from "../table-no-data";
import UserTableRow from "../user-table-row";
import UserTableHead from "../user-table-head";
import TableEmptyRows from "../table-empty-rows";
import UserTableToolbar from "../user-table-toolbar";
import { emptyRows, applyFilter, getComparator } from "../utils";

// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import { ArrowDownwardIcon } from "./Custom-Icons";
// import { ArrowDropDownIcon } from "./Custom-Icons";

import axios from "../../../Config/Interceptor" 
// import { useSelector } from "react-redux";
// import {  Base_URL } from "../../../Config/Config";
// import ExportToExcel from "../../../Excel-Exported/Exporter";

// import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Base_URL } from "../../../Config/Config";
// import AddFeeDetails from "./Add-Fee-Details";
// import { toast } from "react-toastify";
// import { CardHeader } from "@mui/material";
import { Helmet } from 'react-helmet-async';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh", // Maximum height to trigger scrolling if content overflows
  overflowY: "auto", // Enable vertical scrolling
};

// ----------------------------------------------------------------------

export default function UserPage() {
  // const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    if (id !== "") {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = stData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const [stData, setStData] = useState([]);

  // const [load, setLoad] = useState(false);

  const getData = () => {
    axios
      .get(`${Base_URL}admin/get-orders`)
      .then((res) => {
        // console.log(res.data.data);

        setStData(res.data.data.sort((a,b)=>b.temp_order_id - a.temp_order_id));
      })
      .catch(() => {
        setStData([]);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: stData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const [mod_data, setModData] = React.useState({});

  const [open, setOpen] = React.useState(false);
  const handleOpen = (dt, j) => {
    setModData(j);
    setOpen(true);
  };
  const handleClose = () => {
    setModData({});
    setOpen(false);
  };

  // const addProduct = () => {
  //   navigate("/add-product");
  // };

  const notFound = !dataFiltered.length && !!filterName;

  // const [feeData, setFeeData] = useState({});

  // const getFeeDetails = (st_id) => {
  //   axios
  //     .get(Base_URL + "get-st-fee-dt", { params: { _id: st_id } })
  //     .then((res) => {
  //       setFeeData(res.data.data);

  //       console.log(res.data.data);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };

  const ModelBox = () => {
    return (
     
      <div>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} >
        
          <div style={{width: '100%', margin: 'auto', border: '1px solid #ddd', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}>
              <div style={{padding: '20px'}}>
              <h1 style={{fontSize: '24px', fontWeight: 'bold', textAlign: 'center'}}>Order Details</h1>
              <div>
                  <table  style={{width: '100%', borderCollapse: 'collapse', margin: '20px 0'}}>
                      <thead >
                          <tr style={{backgroundColor: '#f4f4f4', color: 'blue'}}>
                              <th style={{border: '1px solid #ddd', padding: '3px', textAlign: 'left', fontSize:'14px'}} colSpan="3">Order Details</th>
                          </tr>
                      </thead>
                  <tbody>
                      <tr>
                          <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}>
                              Order ID:  {mod_data.temp_order_id}<br/>
                              Date Added: {moment(mod_data.created_on).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}<br/>
                              </td>
                          <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}> 
                              Sub-Total: ₹ {mod_data.subtotal}<br/>
                              {mod_data.promo_code ? (`PromoCode (`+mod_data.promo_code +'): -'+ mod_data.promo_discount): ''}<br/>
                              Order Total: ₹ {mod_data.total_amount}
                          </td>
                          <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}>
                              Order Status: {mod_data.order_status}<br/>
                              Payment Status: {mod_data.payment_status}<br/>
                              GST Details : {mod_data.business_name ? mod_data.business_name  : 'N/A'} {mod_data.gstin ? mod_data.gstin : ''}
                          </td>
                      </tr>
                  </tbody>
              </table>
              </div>
              <div >
                  <table style={{width: '100%', borderCollapse: 'collapse', margin: '20px 0'}}>
                  <thead>
                      <tr style={{backgroundColor: '#f4f4f4', color: 'blue'}}>
                      <th style={{border: '1px solid #ddd', padding: '3px', textAlign: 'left' ,fontSize:'14px'}} colSpan="2">Shipping Address</th>
                      
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                  <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}} colSpan="2">
                  {/*Name: {mod_data.address.split('Name: ')[1].split(',')[0].trim()}<br/>
                  Mobile: {mod_data.address.split('Mobile: ')[1].split(',')[0].trim()}<br/>
                            Address: {mod_data.address.split('Address: ')[1].split(',')}<br/>*/}
                  {mod_data.address}
                  </td>
                  </tr>
                  
                  </tbody>
              </table>
              </div>
                 


              <div>
              <table style={{width: '100%', borderCollapse: 'collapse', margin: '20px 0'}}>
                  <thead>
                  <tr style={{backgroundColor: '#f4f4f4', color: 'blue'}}>
                      <th style={{border: '1px solid #ddd', padding: '3px', textAlign: 'left', fontSize:'14px'}} colSpan="8">Order Items</th>
                  </tr>
                  <tr>
                      <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>Sr. No.</th>
                      <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>Image</th>
                      <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>Name</th>
                      <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>Size</th>

                      <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>Color</th>
                      <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>Price</th>
                      <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>Quantity</th>
                      <th style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>Total</th>


                  </tr>
                  </thead>
                  <tbody>
                  {
                      
                    mod_data.items && Array.isArray( mod_data.items) &&  mod_data.items.map((item, index) => (
                      
                          <tr key={index}>
                          <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>{index + 1}</td>
                          {
                            item && item.product_full_data &&  item.product_full_data.images[0] ?
                            <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>
                            <img alt="Product Image" style={{height: '100px'}} 
                            src={item.product_full_data.images[0]} />
                            </td>
                            :
                            <>No Image Available</>

                          }
                          <td style={{border: '1px solid #ddd', padding: '8px'}} >
                          {
                            item && item.product_full_data ? 
                            <td>
                           {item.product_full_data.name}</td>
                            :
                            <></>
                          }
                          </td>
                         
                          <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>{item.size && item.size != 'none'? item.size : "N/A"}</td>
                          <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>{item.color && item.color != 'none' ? item.color : "N/A"}</td>
                          <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>₹ {item.s_price}</td>
                          <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>{item.quantity} Nos</td>
                          <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>₹ {item.s_price * item.quantity}</td>

                          </tr>
                      ))
                  }

                  </tbody>
              </table>
              </div>
          </div>
          </div>
          </Box>
        </Modal>
      </div>
    );

  };

  // const [open_fee, setOpenFee] = React.useState(false);

  const handleOpenFee = (dt, j) => {
    setModData(j);
    // setOpenFee(true);
  };

  // const handleCloseFee = () => {
  //   setModData({});
  //   setOpenFee(false);
  // };

  return (

    <Container>
    
      <Helmet>
        <title> Orders | Dealkarts </title>
      </Helmet>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Deal Orders</Typography>

       {/* <Button
          variant="contained"
          onClick={addProduct}
          color="inherit"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Add Order
       </Button>*/}
      </Stack>

      <Card>
        <UserTableToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          stData={stData}
        />

        <Scrollbar>
          <TableContainer sx={{ overflow: "unset" }}>
            <Table sx={{ minWidth: 800 }}>
              <UserTableHead
                order={order}
                orderBy={orderBy}
                rowCount={stData.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                onSelectAllClick={handleSelectAllClick}
                headLabel={[
                  { id: "name", label: "Name" },
                  { id: "mobile_no", label: "Mobile" },
                  // { id: 'college', label: 'College' },
                  // { id: "address", label: "Address" },
                  { id: "order_status", label: "Order Status" },
                  { id: "payment_status", label: "Payment Status" },
                  { id: "subtotal", label: "subtotal" },
                  { id: "total_amount", label: "Total Amount" },
                  { id: "created_on", label: "Created On" },

                  { id: "View", label: "View" },
                  // { id: '', label: '' },
                  // { id: "action", label: "Action" },
                ]}
              />
              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row,index) => {
                    return (
                      <UserTableRow
                      key={index}
                        mobile_no={row.address.split('Mobile: ')[1]?.split(',')[0].trim()}
                        name={row.address.split('Name: ')[1]?.split(',')[0].trim()}
                        // address={row.address}
                        order_id={row.temp_order_id}
                        order_status={row.order_status}
                        payment_status={row.payment_status}
                        subtotal={row.subtotal}
                        total_amount={row.total_amount}
                        created_on={row.created_on}
                        selected={selected.indexOf(row.name) !== -1}
                        handleClick={(event) => handleClick(event, row.name)}
                        open_model={(e) => handleOpen(e, row)}
                        open_model_fee={(e) => handleOpenFee(e, row)}
                        _id={row.user_id}
                        getData={getData}
                        row_data={row}
                        is_fee_details={row.is_fee_details}
                      />
                    );
                  })}

                <TableEmptyRows
                  height={77}
                  emptyRows={emptyRows(page, rowsPerPage, stData.length)}
                />

                {notFound && <TableNoData query={filterName} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          page={page}
          component="div"
          count={stData.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <ModelBox />
    </Container>
  );
}
