import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'Verified Students',
  //   path: '/veri-stu',
  //   icon: icon('ic_lock'),
  // },
  {
    title: 'Users',
    path: '/user',
    icon: icon('ic_user'),
  },
  // { 
  //   title: 'Best Offers Data',
  //   path: '/best-offers',
  //   icon: icon('ic_cart'),
  // },
  // { 
  //   title: 'Best Offers Data',
  //   path: '/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'Brochures Data',
  //   path: '/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'Brochures Data',
  //   path: '/bros-data',
  //   icon: icon('ic_blog'),
  // },
  
  // {
  //   title: 'LNB Id Seq.',
  //   path: '/add-id-seq',
  //   icon: icon('ic_lock'),
  // },

  {
    title: 'Products',
    path: '/deal-product',
    icon: icon('ic_lock'),
  },

  {
    
    title: 'Banners',
    path: '/deal-banners',
    icon: icon('ic_blog'),
  },

  {
    title: 'Orders',
    path: '/deal-orders',
    icon: icon('ic_cart'),
  },

  {
    title: 'Promo',
    path: '/deal-promo',
    icon: icon('ic_cart'),
  },

  {
    title: 'SizeChart',
    path: '/deal-size',
    icon: icon('ic_lock'),
  },

 

  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
