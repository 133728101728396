





const CountReducer = (state , action) =>{

    if(typeof  state == 'undefined')
    {
        return{

            BRO_COUNT : 0,
            GBO_COUNT  :0
        }
    }

    switch(action.type)
    {


        case "BRO":
            return{
                ...state,
                BRO_COUNT :  action.count
            }
        case "GBO":
            return{
                ...state,
                GBO_COUNT :  0            }
            

        default:
            return{
                ...state
            }
    }



}

export default CountReducer