import  { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
// import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
// import FormHelperText from "@mui/material/FormHelperText";
// import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';

import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
// import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import {
//   collegeOptions,
//   mode,
//   months,
//   Tech,
//   duration,
//   Hear_About_us,
//   year,
//   course,
// } from "./Data";
import axios from "../../../Config/Interceptor";
import { toast } from "react-toastify";
import { Base_URL } from "../../../Config/Config";
// import { useNavigate } from "react-router-dom";

export default function EditStudent({
  _id,
  name,
  setOpenFee_handler,
  is_fee_details,
}) {
  //   const userToken  =  useSelector((state)=> state.LoginReducer && state.LoginReducer.data && state.LoginReducer.data.token ? state.LoginReducer.data.token : null )

  useEffect(() => {}, []);

  //   const {state} = useLocation()

  //   const [age, setAge] = React.useState('');

  // const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleChangeEdit = (e) => {
    setFeeData({ ...feeData, [e.target.name]: e.target.value });
  };

  const [feeData, setFeeData] = useState({});

  useEffect(() => {
    if (is_fee_details) {
      axios
        .get(Base_URL + "get-st-fee-dt", { params: { _id: _id } })
        .then((res) => {
          setFeeData(res.data.data);

          console.log(res.data.data);
        })
        .catch(() => {
          setFeeData({});
        });
    }
  }, []);

  const [formData, setFormData] = useState({
    _id: _id,
    counsellor_name: "",
    total_fee: 0,
    reg_fee_paid: 0,
    remaining_fee: 0,
    payment_status: "",
  });
  

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleInputEdit = (e) => {
    setFeeData({ ...feeData, [e.target.name]: e.target.value });
  };

  const submitFormData = () => {
    axios
      .post(Base_URL + "add-fee-details", formData)
      .then((res1) => {
        toast.success(res1.data.message);
        setOpenFee_handler(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const submitFormDataEdit = () => {
    console.log(feeData);

    axios
      .post(Base_URL + "update-fee-details", feeData)
      .then((res1) => {
        toast.success(res1.data.message);
        setOpenFee_handler(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // const [FeeStatusOptions, setFeeStatusOptions] = React.useState([
  //   "Paid",
  //   "Pending",
  //   "Partial",
  // ]);

  return (
    <Container>
      <Card variant="outlined">
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4">
              {is_fee_details ? "Edit" : "Add"} Student Fee Details{" "}
            </Typography>
          </Stack>
          <Typography style={{ color: "green", fontSize: 13 }} variant="h6">
            {" "}
            You are adding fee details for {name}{" "}
          </Typography>

          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            {!is_fee_details ? (
              <FormControl style={{ width: "100%" }}>
                <InputLabel htmlFor="component-outlined">
                  Counsellor Name
                </InputLabel>
                <OutlinedInput
                  name="counsellor_name"
                  value={formData.counsellor_name}
                  id="component-outlined"
                  label="Counsellor Name"
                  onChange={handleInput}
                />
              </FormControl>
            ) : (
              <FormControl style={{ width: "100%" }}>
                <InputLabel htmlFor="component-outlined">
                  Counsellor Name
                </InputLabel>
                {Object.keys(feeData).length > 0 ? (
                  <OutlinedInput
                    name="counsellor_name"
                    value={feeData.counsellor_name}
                    id="component-outlined"
                    // label="Counsellor Name"
                    onChange={handleInputEdit}
                  />
                ) : null}
              </FormControl>
            )}
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="component-outlined">Total Fee</InputLabel>
              {!is_fee_details ? (
                <OutlinedInput
                  name="total_fee"
                  value={formData.total_fee}
                  id="component-outlined"
                  label="Total Fee"
                  onChange={handleInput}
                />
              ) : (
                <>
                  {Object.keys(feeData).length > 0 ? (
                    <OutlinedInput
                      name="total_fee"
                      value={feeData.total_fee}
                      defaultValue={feeData.total_fee}
                      id="component-outlined"
                      label="Total Fee"
                      onChange={handleInputEdit}
                    />
                  ) : null}
                </>
              )}
            </FormControl>
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="component-outlined">
                Paid Reg. Fee
              </InputLabel>
              {!is_fee_details ? (
                <OutlinedInput
                  name="reg_fee_paid"
                  value={formData.reg_fee_paid}
                  id="component-outlined"
                  label="Paid Reg. Fee"
                  onChange={handleInput}
                />
              ) : (
                <>
                  {Object.keys(feeData).length > 0 ? (
                    <OutlinedInput
                      name="reg_fee_paid"
                      value={feeData.reg_fee_paid}
                      id="component-outlined"
                      label="Paid Reg. Fee"
                      onChange={handleInputEdit}
                    />
                  ) : null}
                </>
              )}
            </FormControl>
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="component-outlined">
                Remaining Fee
              </InputLabel>
              {!is_fee_details ? (
                <OutlinedInput
                  name="remaining_fee"
                  value={formData.remaining_fee}
                  id="component-outlined"
                  label="Remaining Fee"
                  onChange={handleInput}
                />
              ) : (
                <>
                  {Object.keys(feeData).length > 0 ? (
                    <OutlinedInput
                      name="remaining_fee"
                      value={feeData.remaining_fee}
                      id="component-outlined"
                      label="Remaining Fee"
                      onChange={handleInputEdit}
                    />
                  ) : null}
                </>
              )}
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Payment Status
              </InputLabel>
              {!is_fee_details ? (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.payment_status}
                  name="payment_status"
                  label="Payment Status"
                  onChange={handleChange}
                >
                  {/*{FeeStatusOptions.map((el, i) => (
                    <MenuItem value={el}>{el}</MenuItem>
                  ))}*/}
                </Select>
              ) : (
                <>
                  {Object.keys(feeData).length > 0 ? (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={feeData.payment_status}
                      name="payment_status"
                      label="Payment Status"
                      onChange={handleChangeEdit}
                    >
                      {/*{FeeStatusOptions.map((el, i) => (
                        <MenuItem value={el}>{el}</MenuItem>
                      ))}*/}
                    </Select>
                  ) : null}
                </>
              )}
            </FormControl>
            <Stack spacing={2} direction="row">
              {is_fee_details ? (
                <Button
                  onClick={submitFormDataEdit}
                  style={{ width: "100%" }}
                  variant="contained"
                >
                  Edit{" "}
                </Button>
              ) : (
                <Button
                  onClick={submitFormData}
                  style={{ width: "100%" }}
                  variant="contained"
                >
                  Submit{" "}
                </Button>
              )}
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

EditStudent.propTypes = {
  _id: PropTypes.any,
  name: PropTypes.any,
  setOpenFee_handler: PropTypes.func,
  is_fee_details: PropTypes.any,
};