/* eslint-disable */ 
import { useState } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Iconify from '../..//components/iconify';
import axios from "../../Config/Interceptor" 
import { Base_URL } from '../../Config/Config';
import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function UserTableRow({
  selected,
  _id,
  size,
  bust,
  waist,
  handleClick,
  open_model,
  getData,
  row_data,
}) {

  // const navigate = useNavigate()

  
  const [open, setOpen] = React.useState(false);
  
  const [open_dilo, setOpenDilo] = useState(false);
  const [open_dilo_del, setOpenDiloDel] = useState(false);
  const [temp_id , setTempID] = useState("")
  // const [load , setLoad ] = useState(false)
  const handleClickOpenDilo = (_id) => {
    setOpenDilo(true);
    setTempID(_id)
  };
  const handleClickOpenDiloDelete = () => {
    setOpenDiloDel(true);
    setTempID(_id)
  };

  const handleCloseDilo = (e,r) => {
    setOpenDilo(false);
    if(r === 1)
    {

      handleMoveToVerify(temp_id)
    }
  };
  
  const handleCloseDiloDelete = (e,r) => {
    setOpenDiloDel(false);
    if(r === 1)
    {

      handleDelete(temp_id)
    }
  };

  const Dilo_model  = (_id)=>{
    return (
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpenDilo}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open_dilo}
          onClose={handleCloseDilo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Before Move"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do You Really Want to Move this Student to Verified ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e)=>handleCloseDilo(e,0)}>Disagree</Button>
            <Button onClick={(e)=>handleCloseDilo(e,1)} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }


  const Dilo_model_delete  = (_id)=>{
    return (
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpenDilo}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open_dilo_del}
          onClose={handleCloseDiloDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Before Move"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do You Really Want to Delete this Product  ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e)=>handleCloseDiloDelete(e,0)}>Disagree</Button>
            <Button onClick={(e)=>handleCloseDiloDelete(e,1)} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }



  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (id) => {
    console.log(id)
    // navigate(`/edit-product/${id}` , {state : {...row_data , is_from_simple_user : true}})
    setOpen(null);
  };


 
  const handleDelete = (id)=>{
    console.log(id)

    axios.post(`${Base_URL}admin/delete-size-detail/${id}`).then((res)=>{
      getData();
      toast.success(res.data.message)
      // navigate("/deal-size");
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })
  }

  // const label = { inputProps: { 'aria-label': 'Switch demo' } };

 

  

  return (
    <>
    <Dilo_model/>
    <Dilo_model_delete/>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>

        <TableCell>{_id}</TableCell>
        <TableCell>{size}</TableCell>
        <TableCell>{bust}</TableCell>
        <TableCell>{waist}</TableCell>

        <TableCell align="left">
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
          </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >


        <MenuItem onClick={()=>handleDelete(_id)} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }}  />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}

// UserTableRow.propTypes = {
//   avatarUrl: PropTypes.any,
//   company: PropTypes.any,
//   handleClick: PropTypes.func,
//   isVerified: PropTypes.any,
//   name: PropTypes.any,
//   role: PropTypes.any,
//   selected: PropTypes.any,
//   status: PropTypes.string,
// };









