

import { Link } from '@mui/material';
import { RouterLink } from '../../routes/components';

// ----------------------------------------------------------------------

const Logo = ()=> {
 
  return (
    <Link component={RouterLink} href="/" sx={{ marginLeft:"30px", marginTop:"4px" }}  >
      <img src='https://dealkarts.in/images/navlogo.png' width="60px" height='60px' />
    </Link>
  );
};

export default Logo;
