// import {useState} from 'react'

import Router from './sections'
import Router2  from './AuthSections'

import {useSelector} from 'react-redux'



const FinalRoutingSection  =  () =>{



    const auth  =  useSelector((state)=> state.LoginReducer && state.LoginReducer.data && state.LoginReducer.data.token && typeof(state.LoginReducer.data.token) == 'string' && state.LoginReducer.data.token !== "" && state.LoginReducer.data.token !== null && state.LoginReducer.data.token !== undefined ? true : false)

    // const [auth, setAuth]  = useState(false)


 return(
    <>

    {auth  == true ? <Router /> : <Router2/> }
 
    </>

 )   


}

export default FinalRoutingSection