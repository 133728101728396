// ----------------------------------------------------------------------




export const account = {
  displayName: 'Dealkarts Admin',
  email: 'placedealsonkart@gmail.com',
  photoURL: '/assets/images/avatars/avatar_25.jpg',
};
